import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { updateMetaTagsUrl } from '../utils';
import LocationContext from './context';

const LocationProvider = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        updateMetaTagsUrl(window.location.href);
    }, [location.pathname]);

    return <LocationContext.Provider value={[]}>{children}</LocationContext.Provider>;
};

export default LocationProvider;
