import { IonContent, IonPage, useIonToast } from '@ionic/react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Modal,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { closeCircleSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import background from '../../assets/images/create-video-bg.png';
import thumbnail_placeholder from '../../assets/media/event_thumbnail_placeholder.jpg';
import { CONVERT_STATUS, UPLOADING_STATUS } from '../../constants';
import {
    CONNECT_PRICE_CHANGE,
    CONVERSION_STATUS,
    CONVERT_VIDEO,
    CREATE_EVENT,
    SET_CAMERA_FACING_MODE,
    SET_IVS_STREAM_CONFIG,
    SET_STREAMING_CLIENT,
    SET_VIDEO_DEVICES,
    SET_VIDEO_SELECT_DEVICE,
    SET_VIDEO_THUMBNAIL,
    SET_VIDEO_TITLE,
    SPINNER_VISIBLE,
    UPLOAD_VIDEO
} from '../../constants/actionTypes';
import fetchData from '../../fetch';
import { getBase64 } from '../../helpers/FileReader';
import { checkPermissions } from '../../utils';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import StreamingStats from '../General/StreamingStats';
import { EventTypes } from '../Profile/Streaming/Helpers/eventTypes';
import CameraPreview from './CameraPreview';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import PermissionsModal from './PermissionsModal';
import TagsInput from './TagsInput';
import Thumbnails from './Thumbnails';
import styles from './createVideo.module.scss';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'block',
        background: 'linear-gradient(#291d42, black, black)',
        color: 'white'
    },
    headerWrapper: {
        display: 'block',
        textAlign: 'center'
    },
    closeButton: {
        position: 'absolute !important',
        top: 5,
        right: 5
    },
    contentContainer: {
        display: 'block',
        height: '100%',
        color: 'white',
        padding: 20
        // backgroundImage: `url(../../assets/images/create-video-bg.png)`
        // padding: theme.spacing(2),
        // marginTop: theme.spacing(2),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 8,
        padding: 8,
        // border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 8,
        color: 'white' // Set text color to white
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'gray'
        },
        color: 'white',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        }
    },
    label: {
        '& .MuiInputLabel-root': {
            color: 'gray'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white'
        }
    },
    textFieldError: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'red !important'
        },
        color: 'red !important',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        }
    },
    labelError: {
        '& .MuiInputLabel-root': {
            color: 'red !important'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'red !important'
        }
    },
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'lightgray'
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#32E7B2'
        }
    },
    datepicker: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '& .MuiInputLabel-root': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            color: 'white'
        }
    }
}));

// const streamConfig = IVSBroadcastClient.STANDARD_LANDSCAPE;

// const API_ENDPOINTS = {
//     createStream: `${process.env.REACT_APP_API_URL}/ivs/createStream`,
//     prepareStreamEvent: `${process.env.REACT_APP_API_URL}/ivs/prepare-stream`
// };

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        convertVideoJobIds: state.schedule.convertVideoJobIds,
        conversionStatus: state.schedule.conversionStatus,
        company: state.common.company,
        spinner: state.common.spinner,
        upload: state.common.upload,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};
const mapDispatchToProps = (dispatch) => ({
    setIvsConfig: (ivsConfig) =>
        dispatch({
            type: SET_IVS_STREAM_CONFIG,
            payload: ivsConfig
        }),
    setStreamingClient: (streamingClient) =>
        dispatch({
            type: SET_STREAMING_CLIENT,
            payload: streamingClient
        }),
    onNewEvent: (payload) => dispatch({ type: CREATE_EVENT, payload }),
    onConvertVideo: (payload) => dispatch({ type: CONVERT_VIDEO, payload }),
    onUploadVideo: (payload) => dispatch({ type: UPLOAD_VIDEO, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onRequestForConversionStatus: (payload) => dispatch({ type: CONVERSION_STATUS, payload }),
    onSetVideoTitle: (payload) => dispatch({ type: SET_VIDEO_TITLE, payload }),
    onSetVideoThumbnail: (payload) => dispatch({ type: SET_VIDEO_THUMBNAIL, payload }),
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload }),
    setCameraFacingMode: (payload) => dispatch({ type: SET_CAMERA_FACING_MODE, payload }),
    setVideoDevices: (payload) => dispatch({ type: SET_VIDEO_DEVICES, payload }),
    setVideoSelectDevice: (payload) => dispatch({ type: SET_VIDEO_SELECT_DEVICE, payload })
});

const CreateVideoPage = (props) => {
    const date = new Date();
    const now = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

    const classes = useStyles();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState(0);
    const [showPriceField, setShowPriceField] = useState({});
    const [showDatepicker, setShowDatePicker] = useState(false);
    const [videoTitle, setVideoTitle] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    // const [videoTitle, setVideoTitle] = useState('');
    // const [videoDescription, setVideoDescription] = useState('');
    // const [videoTitle, setVideoTitle] = useState('');
    const [videoTitleError, setVideoTitleError] = useState('');
    // const [videoDescription, setVideoDescription] = useState('');
    const [videoDescriptionError, setVideoDescriptionError] = useState('');
    const [streamDetails, setStreamDetails] = useState(false);
    const inputRefs = useRef([]);
    const { t } = useTranslation();
    const eventTypes = EventTypes();

    const [imagePreview, setImagePreview] = useState(null);
    const [photo, setPhoto] = useState('');

    const { data: user } = props.user;

    const [eventType, setEventType] = useState('video_event');
    const [streamType, setStreamType] = useState('url_pull');
    const [videoFile, setVideoFile] = useState(null);
    const [videoFileType, setVideoFileType] = useState(null);

    const [errorUploading, setErrorUploading] = useState('');

    const [endDateFieldEnabled, setEndDateFieldEnabled] = useState(false);
    const [videoDuration, setVideoDuration] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [eventProducts, setEventProducts] = useState([]);
    const [jobId, setJobId] = useState(uuidv4());
    const [fileUpload, setFileUpload] = useState(false);
    const [fileUploadError, setFileUploadError] = useState('');
    const upload = props.upload.find((x) => x.jobId === jobId);
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const fileInputRef = useRef(null);
    const thumbnailInputRef = useRef(null);
    const PRODUCT_FIELDS_PREFIX = 'product.';
    const [subExclusive, setSubExclusive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().add(15, 'minute'));
    const [openModal, setOpenModal] = useState(false);
    const [exclusiveSwitch, setExclusiveSwitch] = useState(false);
    const [titleClassName, setTitleClassName] = useState(`${classes.textField} ${classes.label}`);
    const [descriptionClassName, setDescriptionClassName] = useState(
        `${classes.textField} ${classes.label}`
    );
    const [videoOrientation, setVideoOrientation] = useState('');
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    const [present] = useIonToast();
    const presentToast = (message) => {
        present({
            message: message,
            duration: 1500,
            position: 'top',
            icon: closeCircleSharp,
            color: 'danger'
        });
    };

    const {
        register,
        reset,
        handleSubmit,
        control,
        formState: { errors, defaultValues, isValid }
    } = useForm({
        defaultValues: {}
    });

    const handleFocus = (index) => () => {
        if (index == 0) {
            setTitleClassName(`${classes.textField} ${classes.label}`);
            setVideoTitleError('');
        }
        if (index == 1) {
            setDescriptionClassName(`${classes.textField} ${classes.label}`);
            setVideoDescriptionError('');
        }

        inputRefs.current[index].style.color = 'white';
    };

    const handleBlur = (index) => () => {
        inputRefs.current[index].style.color = 'gray';
    };

    const registerRef = (ref) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
        }
    };

    const handleTabChange = (event, newValue) => {
        setVideoTitleError('');
        setVideoDescriptionError('');
        setFileUploadError('');
        setTitleClassName(`${classes.textField} ${classes.label}`);
        setDescriptionClassName(`${classes.textField} ${classes.label}`);
        setActiveTab(newValue);
        fileInputRef.current = null;
        thumbnailInputRef.current = null;
        setImagePreview(null);
        setStreamDetails(false);
    };

    const handleCloseClick = () => {
        // Handle close button click logic here
        history.push('/events');
    };

    useEffect(() => {
        if (videoFile && videoFile?.name) {
            setVideoFileType(videoFile.name.toLowerCase().split('.').pop());
        }
    }, [videoFile]);

    useEffect(() => {
        if (videoFileType) {
            props.onUploadVideo({ file: videoFile, type: videoFileType, jobId });
        }
    }, [videoFileType]);

    useEffect(() => {
        if (errorUploading) {
            props.onSpinner(false);
            setTimeout(() => {
                props.setScheduleEventOpen ? props.setScheduleEventOpen(false) : history.goBack();
            }, 5000);
        }
    }, [errorUploading]);

    useEffect(() => {
        let defaultValues = {};
        let showPriceField = {};
        if (props.connected_prices && props.connected_prices.length > 0) {
            for (const eventProduct of eventProducts) {
                showPriceField = { ...showPriceField, [eventProduct.id]: false };
                defaultValues[eventProduct.id] = props.connected_prices
                    .filter((p) => p.product === eventProduct.id)
                    .map((p) => fromUnitAmount(p.price.unit_amount, p.price.currency))
                    .pop();
            }
        }

        reset({ product: { ...defaultValues } });
        setShowPriceField(showPriceField);
    }, [props.connected_prices, eventProducts]);

    useEffect(() => {
        if (props.connected_products && props.connected_products.length > 0) {
            setEventProducts(
                props.connected_products.filter((ca) => ca.metadata['setup-price'] === 'event')
            );
        }
    }, [props.connected_products]);

    let profilePicture;
    let initials;

    if (user.picture) {
        profilePicture = user.picture;
    } else {
        initials = user.given_name.charAt(0) + user.family_name.charAt(0);
    }
    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                140,
                90,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob'
            );
        });
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            presentToast(
                'Please select a valid image file (jpg, jpeg, png).',
                closeCircleSharp,
                'danger'
            );
            return;
        }

        reader.onloadend = () => {
            setImagePreview(reader.result);
        };

        try {
            if (currentFile) {
                const image = await resizeFile(currentFile);
                setPhoto(image);
                reader.readAsDataURL(image);
            }
        } catch (err) {
            console.error('ERROR with thumbnail: ', err);
        }
    };

    const disableButton = () => {
        if (streamType === 'url_pull' && (!fileUpload || !isValid)) {
            return true;
        }
    };

    const deleteVideo = () => {
        setFileUpload(false);
        setVideoFile(null);
        setVideoDuration(null);
        setVideoFileType(null);
        setImagePreview(null);
    };

    const handleVideoUpload = async (event) => {
        const allowedVideoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm'];

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedVideoExtensions.includes(fileExtension)) {
            presentToast('Please upload a video');
            return;
        }
        // if (!event.target.files[0].type.startsWith('video/')) {
        //     presentToast('Please upload a video');
        //     return;
        // }
        setFileUploadError('');
        setFileUpload(true);
        setVideoFile(event.target.files[0]);
        if (event.target.files[0]) {
            await getVideoDuration(event.target.files[0]);
        }
    };

    const getVideoDuration = async (video) => {
        setVideoDuration(null);
        if (!video?.type?.includes('video/')) {
            setEndDateFieldEnabled(true);
        } else if (endDateFieldEnabled) {
            setEndDateFieldEnabled(false);
        }
        try {
            let _fileReader = new FileReader();
            _fileReader.onload = function (e) {
                let blob = new Blob([e.target.result], {
                        type: video.type
                    }),
                    // eslint-disable-next-line no-undef
                    url = (URL || webkitURL).createObjectURL(blob),
                    videoDom = document.createElement('video');
                videoDom.preload = 'metadata';
                videoDom.addEventListener('loadedmetadata', function () {
                    setVideoDuration(videoDom.duration);
                    const isPortrait = videoDom.videoHeight > videoDom.videoWidth;
                    setVideoOrientation(isPortrait ? 'portrait' : 'landscape');
                    // eslint-disable-next-line no-undef
                    (URL || webkitURL).revokeObjectURL(url);
                });

                videoDom.src = url;
            };
            _fileReader.readAsArrayBuffer(video);
        } catch (e) {
            console.warn({ e });
            setEndDateFieldEnabled(true);
            return false;
        }
    };

    const createEvent = async (data) => {
        if (streamType === 'url_pull' && !fileUpload) {
            setFileUploadError(t('RequiredVideo'));
            scrollToTop();
            return;
        }
        if (videoTitle.trim() === '') {
            setTitleClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoTitleError(t('RequiredTitle'));
            scrollToTop();
            return;
        }
        if (videoDescription.trim() === '') {
            setDescriptionClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoDescriptionError(t('RequiredDescription'));
            scrollToTop();
            return;
        }
        console.info(data);
        const startDate = dayjs(data?.start_date?.$d).valueOf() ?? now;
        let endDate = null;
        if (videoDuration && !isNaN(videoDuration) && startDate) {
            endDate = new Date(new Date(startDate).getTime() + Math.ceil(videoDuration) * 1000);
        }

        if (startDate) {
            if (startDate < now) {
                presentToast('Please select date in the future');
            } else {
                let _thumbnail = null;
                setLoading(true);
                try {
                    if (photo) {
                        const base64Image = await getBase64(photo);
                        const data = base64Image.split(';base64,')[1];
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                img: {
                                    data,
                                    name: new Date().getTime()
                                }
                            })
                        };
                        const res = await fetchData.Event.UploadThumbnail(requestOptions);

                        _thumbnail = { ..._thumbnail, _ref: res._id };
                        setThumbnail(_thumbnail);
                    }

                    setThumbnail(_thumbnail);
                    const _url = upload?.url;
                    // const _eventTitle = data.title;
                    // const _description = data.description;
                    const _eventSlug = videoTitle.toLowerCase().replace(/[^a-z0-9]/gi, '-');
                    const _eType = eventTypes.find((et) => et.value === eventType);
                    const _resource_creation_time = 1.5;
                    let priceId;

                    for (let product of Object.keys(data.product)) {
                        if (data[product] && data.product[product]) {
                            if (defaultValues.product[product] != data.product[product]) {
                                const price = await fetchData.Prices.Create(
                                    product,
                                    props.connected_account.accountId,
                                    data.product[product]
                                );
                                priceId = price.id;
                                props.onPriceChange({ product: product, price });
                            } else {
                                priceId = props.connected_prices
                                    .filter((pp) => pp.product === product)
                                    .map((pp) => pp.price.id)
                                    .pop();
                            }
                        }
                    }

                    console.log(`The video is ${videoOrientation}`);

                    const eventData = {
                        title: videoTitle,
                        slug: {
                            type: 'slug',
                            current: `${_eventSlug}`
                        },
                        description: videoDescription,
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        event_type: _eType.value,
                        stream_type: streamType,
                        endpoint: _url,
                        streamStatus: 'upcoming',
                        processingStatus: _url ? CONVERT_STATUS : UPLOADING_STATUS,
                        user_id: props.user.data.sub,
                        tags: [...(data.tags || []), _eType.title],
                        thumbnail: _thumbnail,
                        correlation_id: jobId,
                        resource_creation_time: _resource_creation_time,
                        pay_per_view_price: priceId,
                        subscriber_exclusive: subExclusive,
                        sports_team: {
                            _ref: process.env.REACT_APP_COMPANY_ID,
                            _type: 'reference'
                        },
                        convert_start_dates: [],
                        convert_end_dates: [],
                        convert_mode: videoOrientation
                    };

                    props.onNewEvent(eventData);
                    //props.onRequestForConversionStatus(_eventSlug);
                    props.setScheduleEventOpen
                        ? props.setScheduleEventOpen(false)
                        : history.goBack();
                } catch (e) {
                    console.error(e.message);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const handleFileUploadClick = (e) => {
        e.stopPropagation();
        fileInputRef.current.click();
    };
    const handleThumbnailUploadClick = (e) => {
        e.stopPropagation();
        thumbnailInputRef.current.click();
    };

    const handleGoLive = async () => {
        if (videoTitle.trim() === '') {
            setTitleClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoTitleError('Title is required.');
            scrollToTop();
            return;
        }
        if (videoDescription.trim() === '') {
            setDescriptionClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoDescriptionError('Description is required.');
            scrollToTop();
            return;
        }
        const permissionsGranted = await checkPermissions();
        if (!permissionsGranted) {
            setShowPermissionsModal(true);
            return;
        }
        // await initStreamConfig();
        props.onSetVideoTitle(videoTitle);
        localStorage.setItem('videoTitle', videoTitle);
        localStorage.setItem('videoDescription', videoDescription);
        localStorage.setItem('tagsLive', JSON.stringify(props.videoTags));
        let _thumbnail = null;
        const setVideoThumbnail = async () => {
            try {
                setLoading(true);
                if (photo) {
                    const base64Image = await getBase64(photo);
                    const data = base64Image.split(';base64,')[1];
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            img: {
                                data,
                                name: new Date().getTime()
                            }
                        })
                    };
                    const res = await fetchData.Event.UploadThumbnail(requestOptions);

                    _thumbnail = { ..._thumbnail, _ref: res._id };
                    props.onSetVideoThumbnail(_thumbnail);
                }
            } catch (e) {
                console.error(e.message);
            } finally {
                setLoading(false);
            }
        };
        await setVideoThumbnail();
        localStorage.setItem('thumbnailLive', JSON.stringify(_thumbnail));
        console.log('_thumbnail', _thumbnail);
        if (streamDetails) {
            history.push('/create-video/stream-details');
        } else {
            history.push('/go-live');
        }
    };

    const handleExclusive = (e) => {
        setExclusiveSwitch(!exclusiveSwitch);
        if (!props?.connected_account?.onboarded) {
            setOpenModal(true);
        } else {
            setExclusiveSwitch(!exclusiveSwitch);
            setSubExclusive(e.target.checked);
        }
    };

    const contentRef = useRef(null);
    const scrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollToPoint(0, 0, 500);
        }
    };
    return (
        <IonPage>
            <IonContent ref={contentRef} scrollEvents={true} className={styles.customBackground}>
                <div>
                    <div className={classes.headerWrapper}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                textAlign={'center'}
                                sx={{ color: 'white ', flex: 1 }}>
                                {t('CreateVideo')}
                            </Typography>
                            <IconButton
                                sx={{ color: 'white', marginLeft: '-40px' }}
                                onClick={handleCloseClick}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Tabs
                            variant="fullWidth"
                            textColor="primary"
                            value={activeTab}
                            onChange={handleTabChange}>
                            <Tab sx={{ color: 'white' }} label="Upload" />
                            <Tab sx={{ color: 'white' }} label="Live" />
                        </Tabs>
                    </div>
                    <div
                        className={styles.paddingBottom}
                        style={{ backgroundImage: `url(${background})` }}>
                        <div className={classes.contentContainer}>
                            {activeTab === 0 && (
                                <form
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                    onSubmit={handleSubmit(createEvent)}>
                                    <div>
                                        <div className={classes.uploadContainer}>
                                            {/* Add your upload input field and upload icon here */}
                                            <div className={styles.fileUpload}>
                                                {imagePreview ||
                                                (videoFile && upload?.progress === 100) ? (
                                                    <div
                                                        className={styles.uploadLabel}
                                                        style={{ position: 'relative' }}>
                                                        <img
                                                            style={{
                                                                height: 'inherit',
                                                                width: '-webkit-fill-available',
                                                                borderRadius: '21px'
                                                            }}
                                                            src={
                                                                imagePreview ||
                                                                thumbnail_placeholder
                                                            }
                                                        />
                                                        <IconButton
                                                            onClick={() => deleteVideo()}
                                                            sx={{
                                                                background: 'lightgray',
                                                                padding: '3px',
                                                                position: 'absolute',
                                                                top: '-10px',
                                                                right: '-10px',
                                                                '&:active, &:focus, &:hover': {
                                                                    background: 'lightgray',
                                                                    padding: '3px',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    right: '-10px'
                                                                }
                                                            }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {!upload?.show && (
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                onChange={handleVideoUpload}
                                                                style={{ display: 'none' }}
                                                                ref={fileInputRef}
                                                            />
                                                        )}
                                                        <label
                                                            htmlFor="upload"
                                                            style={{
                                                                border:
                                                                    fileUploadError.trim() !== ''
                                                                        ? '1px solid red'
                                                                        : ''
                                                            }}
                                                            className={styles.uploadLabel}>
                                                            {upload?.show ? (
                                                                <CircularProgressWithLabel
                                                                    value={upload.progress}
                                                                    size={160}
                                                                    thickness={2}
                                                                />
                                                            ) : (
                                                                <div>
                                                                    <div>Upload video</div>
                                                                    <Typography
                                                                        className={
                                                                            styles.secondLine
                                                                        }>
                                                                        {t('MaxFileSize')}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </label>
                                                    </>
                                                )}
                                            </div>
                                            {fileUploadError !== '' && (
                                                <div style={{ color: 'red', marginTop: '4%' }}>
                                                    {fileUploadError}
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            style={{ marginBottom: '1rem' }}
                                            className={classes.progressBarContainer}>
                                            <StreamingStats />
                                        </div>
                                        <div className={classes.videoDetailsContainer}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{ marginBottom: '1rem' }}>
                                                {t('VideoDetails')}
                                            </Typography>
                                            <TextField
                                                {...register('title')}
                                                name="title"
                                                label={t('VideoTitle')}
                                                fullWidth
                                                InputLabelProps={{
                                                    ref: registerRef
                                                }}
                                                InputProps={{
                                                    style: {
                                                        color: 'white'
                                                    }
                                                }}
                                                value={videoTitle}
                                                className={titleClassName}
                                                sx={{ marginBottom: '1rem' }}
                                                onFocus={handleFocus(0)}
                                                onBlur={handleBlur(0)}
                                                onChange={(e) => {
                                                    setVideoTitle(e.target.value);
                                                }}
                                            />
                                            {videoTitleError !== '' && (
                                                <div style={{ color: 'red', marginBottom: '4%' }}>
                                                    {videoTitleError}
                                                </div>
                                            )}
                                            <TextField
                                                {...register('description')}
                                                name="description"
                                                label={t('VideoDescription')}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                value={videoDescription}
                                                InputLabelProps={{
                                                    ref: registerRef
                                                }}
                                                InputProps={{
                                                    style: {
                                                        color: 'white'
                                                    }
                                                }}
                                                className={descriptionClassName}
                                                onFocus={handleFocus(1)}
                                                onBlur={handleBlur(1)}
                                                onChange={(e) =>
                                                    setVideoDescription(e.target.value)
                                                }
                                            />
                                            {videoDescriptionError !== '' && (
                                                <div style={{ color: 'red', marginTop: '4%' }}>
                                                    {videoDescriptionError}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Typography
                                                variant="subtitle1"
                                                style={{ margin: '1rem 0' }}>
                                                {t('Tags')}{' '}
                                                <span style={{ color: 'gray' }}>
                                                    {t('Optional')}
                                                </span>
                                            </Typography>
                                            {/* Add your tags input field with chips here */}
                                            {/* Example using Material-UI Chip component */}
                                            <Controller
                                                name="tags"
                                                control={control}
                                                //        rules={{ required: true }}
                                                render={({ field: { onChange, ...rest } }) => (
                                                    <TagsInput
                                                        handleFocus={handleFocus(2)}
                                                        handleBlur={handleBlur(2)}
                                                        registerRef={registerRef}
                                                        classes={classes}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div style={{ marginBottom: '1rem' }}>
                                            <Typography sx={{ marginBottom: '1rem' }}>
                                                {t('VideoThumbnails')}
                                            </Typography>
                                            <Thumbnails
                                                imagePreview={imagePreview}
                                                fileInputRef={thumbnailInputRef}
                                                handleFileUploadClick={handleThumbnailUploadClick}
                                                handleImageChange={handleImageChange}
                                                setImagePreview={setImagePreview}
                                            />
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <FormControlLabel
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    margin: '0'
                                                }}
                                                control={
                                                    <Switch
                                                        disabled={videoFile === null}
                                                        color="primary"
                                                        className={classes.switch}
                                                        onChange={() => {
                                                            setShowDatePicker(!showDatepicker);
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography>
                                                        {t('ScheduleVideo')}{' '}
                                                        <span style={{ color: 'gray' }}>
                                                            {t('Optional')}
                                                        </span>
                                                    </Typography>
                                                }
                                                labelPlacement="start"
                                                value="schedule"
                                            />
                                            {showDatepicker && (
                                                <Controller
                                                    name="start_date"
                                                    control={control}
                                                    render={({ field: { onChange, ...rest } }) => (
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDayjs}>
                                                            <DateTimePicker
                                                                disablePast
                                                                className={classes.datepicker}
                                                                label="Choose a date"
                                                                sx={{
                                                                    width: '100%',
                                                                    margin: '3vh 0'
                                                                }}
                                                                open={openDatepicker}
                                                                onClose={() =>
                                                                    setOpenDatepicker(false)
                                                                }
                                                                slotProps={{
                                                                    textField: {
                                                                        onClick: () =>
                                                                            setOpenDatepicker(true)
                                                                    }
                                                                }}
                                                                value={selectedDate}
                                                                closeOnSelect={false}
                                                                onChange={(date) =>
                                                                    setSelectedDate(date)
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            )}
                                            <Typography variant="subtitle1">
                                                {t('MonetizationOptions')}
                                            </Typography>
                                            <div>
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        margin: '0'
                                                    }}
                                                    control={
                                                        <Switch
                                                            checked={
                                                                showPriceField[eventProducts[0]?.id]
                                                                    ? showPriceField[
                                                                          eventProducts[0]?.id
                                                                      ]
                                                                    : false
                                                            }
                                                            color="primary"
                                                            className={classes.switch}
                                                            {...register(`${eventProducts[0]?.id}`)}
                                                            onChange={() => {
                                                                if (
                                                                    !props?.connected_account
                                                                        ?.onboarded
                                                                ) {
                                                                    setOpenModal(true);
                                                                } else {
                                                                    setShowPriceField({
                                                                        ...showPriceField,
                                                                        ...{
                                                                            [eventProducts[0]?.id]:
                                                                                !showPriceField[
                                                                                    eventProducts[0]
                                                                                        ?.id
                                                                                ]
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {t('pay-per-viewPrice')}{' '}
                                                            <span style={{ color: 'gray' }}>
                                                                {t('Optional')}
                                                            </span>
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    key={`label${eventProducts[0]?.id}`}
                                                />
                                                {showPriceField[eventProducts[0]?.id] && (
                                                    <Autocomplete
                                                        disablePortal
                                                        freeSolo
                                                        id="combo-box-demo"
                                                        options={props.connected_prices
                                                            .filter(
                                                                (pp) =>
                                                                    pp.product ===
                                                                    eventProducts[0]?.id
                                                            )
                                                            .map((pp) =>
                                                                fromUnitAmount(
                                                                    pp.price.unit_amount,
                                                                    pp.price.currency
                                                                )
                                                            )}
                                                        getOptionLabel={(option) =>
                                                            typeof option === 'string' ||
                                                            option instanceof String
                                                                ? option
                                                                : option.toString()
                                                        }
                                                        key={`price${eventProducts[0]?.id}`}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                type="number"
                                                                {...params}
                                                                label={t('PriceForContent')}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    ref: registerRef
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            sx={{ color: 'white' }}
                                                                            position="start">
                                                                            <Typography
                                                                                color={'white'}>
                                                                                €
                                                                            </Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                    style: {
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                                className={`${classes.textField} ${classes.label}`}
                                                                sx={{ marginBottom: '1rem' }}
                                                                onFocus={handleFocus(3)}
                                                                onBlur={handleBlur(3)}
                                                                {...register(
                                                                    `${PRODUCT_FIELDS_PREFIX}${eventProducts[0]?.id}`,
                                                                    {
                                                                        required: true
                                                                    }
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </div>
                                            <FormControlLabel
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    margin: '0'
                                                }}
                                                control={
                                                    <Controller
                                                        name="exclusive"
                                                        control={control}
                                                        //        rules={{ required: true }}
                                                        render={({
                                                            field: { onChange, ...rest }
                                                        }) => (
                                                            <Switch
                                                                checked={exclusiveSwitch}
                                                                color="primary"
                                                                className={classes.switch}
                                                                onChange={(e) => handleExclusive(e)}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={
                                                    <Typography>
                                                        {t('ExclusiveToSubscribers')}{' '}
                                                        <span style={{ color: 'gray' }}>
                                                            {t('Optional')}
                                                        </span>
                                                    </Typography>
                                                }
                                                labelPlacement="start"
                                                value="exclusive"
                                            />
                                        </div>
                                        {loading ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <CircularProgress
                                                    sx={{
                                                        width: '60px !important',
                                                        height: '60px !important'
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                type="submit"
                                                // onClick={handleSubmit(createEvent)}
                                                className={styles.button}
                                                sx={{
                                                    '&:active, &:focus, &:hover': {
                                                        backgroundColor:
                                                            videoTitle.trim() === '' ||
                                                            videoDescription.trim() === '' ||
                                                            !fileUpload
                                                                ? '#333'
                                                                : '#32E7B2',
                                                        color:
                                                            videoTitle.trim() === '' ||
                                                            videoDescription.trim() === '' ||
                                                            !fileUpload
                                                                ? '#666'
                                                                : 'black'
                                                    },
                                                    marginTop: '3%',
                                                    backgroundColor:
                                                        videoTitle.trim() === '' ||
                                                        videoDescription.trim() === '' ||
                                                        !fileUpload
                                                            ? '#333'
                                                            : '#32E7B2',
                                                    color:
                                                        videoTitle.trim() === '' ||
                                                        videoDescription.trim() === '' ||
                                                        !fileUpload
                                                            ? '#666'
                                                            : 'black'
                                                }}>
                                                {t('Publish')}
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            )}
                            {activeTab === 1 && (
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.uploadContainer}>
                                                {/* Add your upload input field and upload icon here */}
                                                <div className={styles.fileUpload}>
                                                    <div className={styles.uploadLabel}>
                                                        {/* <LiveStreaming /> */}
                                                        <CameraPreview
                                                            setCameraFacingMode={
                                                                props.setCameraFacingMode
                                                            }
                                                            setVideoDevices={props.setVideoDevices}
                                                            setVideoSelectDevice={
                                                                props.setVideoSelectDevice
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ marginBottom: '1rem' }}
                                                className={classes.progressBarContainer}>
                                                <StreamingStats />
                                            </div>
                                            <div className={classes.videoDetailsContainer}>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{ marginBottom: '1rem' }}>
                                                    {t('VideoDetails')}
                                                </Typography>
                                                <TextField
                                                    label={t('VideoTitle')}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        ref: registerRef
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'white'
                                                        }
                                                    }}
                                                    value={videoTitle}
                                                    className={titleClassName}
                                                    sx={{ marginBottom: '1rem' }}
                                                    onFocus={handleFocus(0)}
                                                    onBlur={handleBlur(0)}
                                                    onChange={(e) => setVideoTitle(e.target.value)}
                                                />
                                                {videoTitleError !== '' && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            marginBottom: '4%'
                                                        }}>
                                                        {videoTitleError}
                                                    </div>
                                                )}
                                                <TextField
                                                    label={t('VideoDescription')}
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    value={videoDescription}
                                                    InputLabelProps={{
                                                        ref: registerRef
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'white'
                                                        }
                                                    }}
                                                    className={descriptionClassName}
                                                    onFocus={handleFocus(1)}
                                                    onBlur={handleBlur(1)}
                                                    onChange={(e) =>
                                                        setVideoDescription(e.target.value)
                                                    }
                                                />
                                                {videoDescriptionError !== '' && (
                                                    <div style={{ color: 'red', marginTop: '4%' }}>
                                                        {videoDescriptionError}
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{ margin: '1rem 0' }}>
                                                    {t('Tags')}{' '}
                                                    <span style={{ color: 'gray' }}>
                                                        {t('Optional')}
                                                    </span>
                                                </Typography>
                                                {/* Add your tags input field with chips here */}
                                                {/* Example using Material-UI Chip component */}
                                                <Controller
                                                    name="tags"
                                                    control={control}
                                                    //        rules={{ required: true }}
                                                    render={({ field: { onChange, ...rest } }) => (
                                                        <TagsInput
                                                            handleFocus={handleFocus(2)}
                                                            handleBlur={handleBlur(2)}
                                                            registerRef={registerRef}
                                                            classes={classes}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginBottom: '1rem' }}>
                                                <Typography sx={{ marginBottom: '1rem' }}>
                                                    {t('VideoThumbnails')}
                                                </Typography>
                                                <Thumbnails
                                                    imagePreview={imagePreview}
                                                    fileInputRef={fileInputRef}
                                                    handleFileUploadClick={handleFileUploadClick}
                                                    handleImageChange={handleImageChange}
                                                    setImagePreview={setImagePreview}
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControlLabel
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            margin: '0'
                                                        }}
                                                        control={
                                                            <Switch
                                                                checked={
                                                                    showPriceField[
                                                                        eventProducts[0]?.id
                                                                    ]
                                                                        ? showPriceField[
                                                                              eventProducts[0]?.id
                                                                          ]
                                                                        : false
                                                                }
                                                                color="primary"
                                                                className={classes.switch}
                                                                {...register(
                                                                    `${eventProducts[0]?.id}`
                                                                )}
                                                                onChange={() => {
                                                                    if (
                                                                        !props?.connected_account
                                                                            ?.onboarded
                                                                    ) {
                                                                        setOpenModal(true);
                                                                    } else {
                                                                        setShowPriceField({
                                                                            ...showPriceField,
                                                                            ...{
                                                                                [eventProducts[0]
                                                                                    ?.id]:
                                                                                    !showPriceField[
                                                                                        eventProducts[0]
                                                                                            ?.id
                                                                                    ]
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography>
                                                                {t('pay-per-viewPrice')}{' '}
                                                                <span style={{ color: 'gray' }}>
                                                                    {t('Optional')}
                                                                </span>
                                                            </Typography>
                                                        }
                                                        labelPlacement="start"
                                                        key={`label${eventProducts[0]?.id}`}
                                                    />
                                                    {showPriceField[eventProducts[0]?.id] && (
                                                        <Autocomplete
                                                            disablePortal
                                                            freeSolo
                                                            id="combo-box-demo"
                                                            options={props.connected_prices
                                                                .filter(
                                                                    (pp) =>
                                                                        pp.product ===
                                                                        eventProducts[0]?.id
                                                                )
                                                                .map((pp) =>
                                                                    fromUnitAmount(
                                                                        pp.price.unit_amount,
                                                                        pp.price.currency
                                                                    )
                                                                )}
                                                            getOptionLabel={(option) =>
                                                                typeof option === 'string' ||
                                                                option instanceof String
                                                                    ? option
                                                                    : option.toString()
                                                            }
                                                            key={`price${eventProducts[0]?.id}`}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    type="number"
                                                                    {...params}
                                                                    label={t('PriceForContent')}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        ref: registerRef
                                                                    }}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        startAdornment: (
                                                                            <InputAdornment
                                                                                sx={{
                                                                                    color: 'white'
                                                                                }}
                                                                                position="start">
                                                                                <Typography
                                                                                    color={'white'}>
                                                                                    €
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        ),
                                                                        style: {
                                                                            color: 'white'
                                                                        }
                                                                    }}
                                                                    className={`${classes.textField} ${classes.label}`}
                                                                    sx={{ marginBottom: '1rem' }}
                                                                    onFocus={handleFocus(3)}
                                                                    onBlur={handleBlur(3)}
                                                                    {...register(
                                                                        `${PRODUCT_FIELDS_PREFIX}${eventProducts[0]?.id}`,
                                                                        {
                                                                            required: true
                                                                        }
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        margin: '0'
                                                    }}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            className={classes.switch}
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {t('ExclusiveToSubscribers')}{' '}
                                                            <span style={{ color: 'gray' }}>
                                                                {t('Optional')}
                                                            </span>
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    value="exclusive"
                                                />
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        margin: '0'
                                                    }}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            className={classes.switch}
                                                            onChange={() =>
                                                                setStreamDetails(!streamDetails)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {t('ExternalStream')}{' '}
                                                            <span style={{ color: 'gray' }}>
                                                                {t('Optional')}
                                                            </span>
                                                        </Typography>
                                                    }
                                                    labelPlacement="start"
                                                    value={streamDetails}
                                                />
                                            </div>
                                            {loading ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                    <CircularProgress
                                                        sx={{
                                                            width: '60px !important',
                                                            height: '60px !important'
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <Button
                                                    onClick={() => handleGoLive()}
                                                    className={styles.button}
                                                    sx={{
                                                        '&:active, &:focus, &:hover': {
                                                            backgroundColor:
                                                                videoTitle.trim() === '' ||
                                                                videoDescription.trim() === ''
                                                                    ? '#333'
                                                                    : '#32E7B2',
                                                            color:
                                                                videoTitle.trim() === '' ||
                                                                videoDescription.trim() === ''
                                                                    ? '#666'
                                                                    : 'black'
                                                        },
                                                        marginTop: '3%',
                                                        backgroundColor:
                                                            videoTitle.trim() === '' ||
                                                            videoDescription.trim() === ''
                                                                ? '#333'
                                                                : '#32E7B2',
                                                        color:
                                                            videoTitle.trim() === '' ||
                                                            videoDescription.trim() === ''
                                                                ? '#666'
                                                                : 'black'
                                                    }}>
                                                    {videoTitle.trim() === '' ||
                                                    videoDescription.trim() === ''
                                                        ? t('GoLive')
                                                        : t('Next')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    {/* Add your live component or content here */}
                                </div>
                            )}
                            <PermissionsModal
                                showPermissionsModal={showPermissionsModal}
                                setShowPermissionsModal={setShowPermissionsModal}
                            />
                            <Modal open={openModal}>
                                <div className={isMobile ? styles.modalMobile : styles.modal}>
                                    <Typography
                                        marginTop={isMobile ? '3%' : '-1%'}
                                        fontSize={'17px'}
                                        textAlign={'center'}>
                                        {t('OnboardingModal1')}
                                    </Typography>
                                    <Typography fontSize={'17px'} textAlign={'center'}>
                                        {t('OnboardingModal2')}
                                    </Typography>
                                    <div className={styles.flexContainer}>
                                        {!loading ? (
                                            <>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.confirmButtonMobile
                                                            : styles.confirmButton
                                                    }
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                        history.push({
                                                            pathname: '/profile/settings/account',
                                                            state: { flag: true }
                                                        });
                                                    }}>
                                                    {t('Onboard')}
                                                </Button>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.cancelButtonMobile
                                                            : styles.cancelButton
                                                    }
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                        setShowPriceField({});
                                                        setExclusiveSwitch(false);
                                                    }}>
                                                    {t('Close')}
                                                </Button>
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    margin: '0 auto'
                                                }}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVideoPage);
